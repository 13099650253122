import { createHandler } from '@/util/events.js'

createHandler('furboQuantity', function (ev) {
  ev.preventDefault()

  const min = 1
  const max = 3

  const display = document.querySelector('.furbo-qty-widget .qty-widget-value')
  let currentQty = parseInt(display.innerText)

  if (ev.target.classList.contains('qty-down')) {
    currentQty--
  } else {
    currentQty++
  }

  if (currentQty < min || currentQty > max) return false

  display.innerText = currentQty

  // Update prices based on quantity
  const selectedBox = document.querySelector('.furbo-box.selected')
  const furboSelect = selectedBox
    ? selectedBox.querySelector('.furbo-select')
    : document.querySelector('.furbo-box.premium .furbo-select')
  const basePrice = selectedBox
    ? parseFloat(furboSelect.dataset.basePrice)
    : 19.99 // Default to premium price if no selection

  const subtotalPrice = document.querySelector('.subtotal-price')
  if (subtotalPrice) {
    subtotalPrice.innerText = (basePrice * currentQty).toFixed(2)
  }

  const strikethroughPrice = document.querySelector('.strikethrough-price')
  if (strikethroughPrice) {
    const baseStrikePrice = parseFloat(furboSelect.dataset.strikePrice)
    strikethroughPrice.innerText =
      '$' + Math.floor(baseStrikePrice * currentQty)
  }

  updateCheckoutInfoFurbo()
})

createHandler('furboSelectOption', function (ev) {
  ev.preventDefault()

  setSelected(ev)
  updateCheckoutInfoFurbo()
})

createHandler('redirectToCheckoutFurbo', function () {
  window.location.href = furboCheckoutUrl()
})

createHandler('furboBSelectOption', function (ev) {
  ev.preventDefault()

  setSelected(ev)

  document
    .querySelector('.furbo-box:not(.selected) .sub-text')
    .classList.add('hidden')
  document
    .querySelector('.furbo-box.selected .sub-text')
    .classList.remove('hidden')

  const payment_container = document.querySelector(
    '.furbo-box .order-button-container',
  )
  const placement_target = document.querySelector(
    '.furbo-box.selected .pre-order-button',
  )

  placement_target.after(payment_container)
})

createHandler('furboBOrderNow', function () {
  const selectedBox = document.querySelector('.furbo-box.selected')

  if (selectedBox == null) {
    document
      .querySelector('.furbo-box.premium')
      .classList.add('selected', 'bg-[#E9F7ED]')
  }

  window.location.href = furboCheckoutUrl()
})

createHandler('furboWarrantyHandler', function (ev) {
  updateCheckoutInfoFurbo()
})

const setSelected = function (ev) {
  Array.from(document.querySelectorAll('.furbo-box')).forEach((node) => {
    node.classList.remove(
      'selected',
      'border-green',
      'shadow-[0_0_0.2em_0_green]',
    )
    node.removeAttribute('style')
  })

  document
    .querySelectorAll('.furbo-box .furbo-select')
    ?.forEach((node) =>
      node.classList.remove(
        '!bg-green',
        '!border-white',
        'shadow-[0_0_0_0.1em_green]',
        '!border-4',
      ),
    )
  ev.currentTarget.classList.add(
    'selected',
    'border-green',
    'shadow-[0_0_0.2em_0_green]',
  )
  ev.currentTarget.style = 'background-color: #E9F7ED'
  document
    .querySelector('.furbo-box.selected .furbo-select')
    .classList.add(
      '!bg-green',
      '!border-white',
      'shadow-[0_0_0_0.1em_green]',
      '!border-4',
    )

  // Update prices based on selected option and current quantity
  const currentQty = parseInt(
    document.querySelector('.qty-widget-value')?.innerText || '1',
  )
  const furboSelect = ev.currentTarget.querySelector('.furbo-select')
  const basePrice = parseFloat(furboSelect.dataset.basePrice)
  const subtotalPrice = document.querySelector('.subtotal-price')
  if (subtotalPrice) {
    subtotalPrice.innerText = (basePrice * currentQty).toFixed(2)
  }

  const strikethroughPrice = document.querySelector('.strikethrough-price')
  if (strikethroughPrice) {
    const baseStrikePrice = parseFloat(furboSelect.dataset.strikePrice)
    strikethroughPrice.innerText =
      '$' + Math.floor(baseStrikePrice * currentQty)
  }

  updateCheckoutInfoFurbo()
  document.querySelector('button.order-now').removeAttribute('disabled')
}

const updateCheckoutInfoFurbo = () => {
  const formActionUrl = document.querySelector('#expressCheckout')

  const newCheckoutURL = furboCheckoutUrl()

  formActionUrl.action = newCheckoutURL
}

const furboCheckoutUrl = function () {
  const selectedBox = document.querySelector('.furbo-box.selected')
  if (selectedBox == null) return false

  const qty = document.querySelector('.qty-widget-value')?.innerText || '1'

  const selectedOffer =
    qty +
    'pk' +
    (selectedBox?.classList?.contains('premium') ? '67pt' : '29') +
    '-furbo'

  return `/buy/sensor-fb/${selectedOffer}`
}
